
import { defineComponent, ref, onMounted } from 'vue'
import { ElSwitch } from 'element-plus'

import { apiErrorNotification } from '@/core/helpers'
import { useWorkers, usePermissions } from '@/core/compositions'
import { EPermissions, IWorker, EWorkersTypes } from '@/types'
import { workersService } from '@/services'

export default defineComponent({
  name: 'OrderStatusesView',

  components: {
    ElSwitch,
  },

  setup() {
    const { hasPermission } = usePermissions()

    const { getWorkersPagination, workers, workersLabels } = useWorkers()

    const loading = ref(true)

    onMounted(() => {
      getData()
    })

    const getData = async () => {
      loading.value = true

      try {
        await getWorkersPagination()
      } catch (error) {
        console.error(error)
        apiErrorNotification(error)
      } finally {
        loading.value = false
      }
    }

    const onIsActiveChange = async (worker: IWorker) => {
      loading.value = true

      try {
        await workersService.update(worker.id, { isActive: worker.isActive })

        getData()
      } catch (error) {
        console.error(error)
        apiErrorNotification(error)
      }
    }

    return {
      loading,
      workers,
      workersLabels,
      hasPermission,
      EPermissions,
      onIsActiveChange,
      EWorkersTypes,
    }
  },
})
